.clearfix
{
  @include clearfix;
}

body
{
	background: #fff;
	padding-top: 110px;
}

.layout-container
{
	@include outer-container;
	margin: 0 auto;
	padding-left: $block-padding-x;
	padding-right: $block-padding-x;
	max-width: 1260px;
}

.container
{
	margin-left: -$block-padding-x;
	margin-right: -$block-padding-x;
	margin-bottom: $space-y;
	@include clearfix;
}

.fh-container
{
	position: relative;
	overflow: hidden;
	&:after
	{
		content: '';
		display: block;
		margin-top: 100%; 
	}
} 

.full-container
{
	@include position(absolute, 0 0 0 0);
	padding: $block-padding;

}


.filled-block
{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}


