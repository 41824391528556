.row-justify
{
  text-align: justify;
  font-size: 1px;
  display: block;
  &:after
  {
    content: ' ';
    display: inline-block;
    width: 100%;
  }
  & > *
  {
    font-size: $font-size-default;
  }
  [class*="col-"]
  {
    float: none;  
    display: inline-block;
    vertical-align: top;
  }
}

.col-inline
{
  display: inline-block;
  vertical-align: middle;
  margin-right: gutter();
}

.va-container
{
  display: table;
  width: 100%;
  height: 100%;
}

.va-middle
{
  display: table-cell;
  vertical-align: middle;
}

