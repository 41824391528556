// Gray colors prototype
$gr1:                         #f2f2f2;
$gr2:                         #e5e5e5;
$gr3:                         #cacaca;



$blue1: rgb(0,161,224);
$blue2: rgb(128,210,241);

$green1: rgb(111,190,68);
$green2: rgb(187,228,153);

$briz: rgb(16,175,169);
$blue3: rgb(0,78,108);
$gray: rgb(118,129,134);
$gray-light: rgb(191,203,209);

// Theme colors
$brand-primary:               $blue1;
$brand-primary-text:          #fff;
$brand-slave:                 $green1;
$brand-slave-text:            #fff;
$text-color:                  #111;

$gray-dark:                   #595959;

// Alert colors
$good-light:                  #b9e7af;
$good-dark:                   #5ba64e;
$bad-light:                   #fdcbbf;
$bad-dark:                    #f62e00;
$neutral-light:               #f6f5be;
$neutral-dark:                #594539;

// Font settings
$font-size-default:           14px;
$font-body:                   'Open Sans', sans-serif;
$font-heading:                'Open Sans', sans-serif;
$font-margin:                 21px;
$font-scale:                  1.608;

// Link settings
$link-color:                  blue;
$link-hover-color:            red;

// Block settings
$space-y:                     1rem;
$input-padding:               6px 12px;
$button-padding:              6px 12px;
$block-padding-x:             15px;
$block-padding-y:             10px;
$block-padding:               $block-padding-y $block-padding-x;


$grid-breakpoints: (
  'xs':  700px,
  'sm':   1024px,
  'md':  1520px,
  'ld':  1450px,
  'lg':  2000px,
  'xl':  2000px,
) !default;
