.header
{
	padding: 15px 0;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 500;
	@include transition;
	background: #fff url(../img/corner.png) no-repeat top left;
	background-size: auto 100%;
	&.collapsed
	{
		padding: 10px 0;
		background: rgba(255,255,255,.85);
	}
	&__wrap
	{
		display: flex;
		justify-content: space-between;
		align-items: center;

	}
	&__nav
	{
		text-align: right;
		ul
		{
			margin: 0;
			padding: 0;
			list-style: none;
			
			li
			{
				display: inline-block;
				margin-left: 15px;
				&.active
				{
					a
					{
						color: $blue2;
					}
				}
				a
				{
					padding: 10px;
					display: block;
					text-transform: uppercase;
					color: $blue3;
					font-weight: bold;
					text-decoration: none;
					@include transition;
					&:hover
					{
						color: $blue2;
					}
				}
			}
		}
	}
	&__logo
	{
		a
		{
			text-decoration: none;
		}
		img
		{
			.collapsed &
			{
				height: 55px;
			}
			vertical-align: middle;
			@include transition;
		}
		span
		{
			font-size: 36px;
			color: $blue3;
			font-weight: 900;
			display: inline-block;
			vertical-align: middle;
			margin-left: 10px;
			@include transition;
			.collapsed &
			{
				height: 26px;
			}
		}
	}
}