.form-horizontal
{
	label
	{
		display: block;
	}
}

.form-vertical
{
	.form-group
	{
		
	}
	label
	{
		text-align: right;
	}
}



.form-group
{
	label
	{
		//font-weight: bold;
		font-family: $font-heading;
		font-size: $font-size-default * 0.9;
	}
	label.error
	{
		font-style: italic;
		font-size: $font-size-default * 0.9;
		color: $bad-dark;
	}
}

.form-control
{
	box-shadow: inset 0 1px 1px rgba(229,229,229,0.75);
	background-color: #fff !important;
	border: 1px solid $gray;
	padding: $input-padding;
	width: 100%;
	box-sizing: border-box;
	&.error
	{
		background: $bad-light !important;
		border-color: $bad-dark !important;
		color: $bad-dark !important;
	}
	&:focus
	{
		border-color: transparentize($brand-primary, 0.75) !important;
		box-shadow: inset 0 1px 1px rgba(229,229,229,0.75), 0 0 8px transparentize($brand-primary, 0.75) !important;
		outline: none;
	}
	&:hover
	{
		border-color: #ccc;
		box-shadow: inset 0 1px 1px rgba(204,204,204,0.75);
	}
}

.form-control-checkbox
{
	display: none;
	& + label
	{
		position: relative;
		color: $gray-dark;
		cursor: pointer;
		padding-left: 2em;
		&:hover
		{
			color: darken($gray-dark, 10%);
			&:before
			{
				border-color: #ccc;
				box-shadow: inset 0 1px 1px rgba(204,204,204,0.75);
			}
		}
		&:before
		{
			content: ' ';
			display: inline-block;
			position: absolute;
			left: 0;
			top: -0.1em;
			width: 1.5em;
			height: 1.5em;
			background: #fff;
			border: 1px solid $gray;
			vertical-align: middle;
			margin-right: .5em;
			box-shadow: inset 0 1px 1px rgba(229,229,229,0.75);
		}
		&:after
		{
			content: ' ';
			display: inline-block;
			position: absolute;
			left: .3em;
			top: .2em;
			width: .9em;
			height: .9em;
			background: $brand-primary;
			border: 1px solid $brand-primary;
			vertical-align: middle;
			margin-right: .5em;
			opacity: 0;
			border-radius: 2px;
		}
	}
	&:checked
	{
		& + label
		{
			&:after
			{
				opacity: 1;
			}
		}
	}
}


.form-control-radio
{
	display: none;
	& + label
	{
		position: relative;
		color: $gray-dark;
		margin-right: $space-y;
		cursor: pointer;
		padding-left: 2em;
		&:hover
		{
			color: darken($gray-dark, 10%);
			&:before
			{
				border-color: #ccc;
				box-shadow: inset 0 1px 1px rgba(204,204,204,0.75);
			}
		}
		&:before
		{
			content: ' ';
			display: inline-block;
			position: absolute;
			left: 0;
			top: -0.1em;
			width: 1.5em;
			height: 1.5em;
			border-radius: 50%;
			background: #fff;
			border: 1px solid $gray;
			vertical-align: middle;
			margin-right: .5em;
			box-shadow: inset 0 1px 1px rgba(229,229,229,0.75);
		}
		&:after
		{
			content: ' ';
			display: inline-block;
			position: absolute;
			left: .3em;
			top: .2em;
			width: .9em;
			height: .9em;
			background: $brand-primary;
			border: 1px solid $brand-primary;
			vertical-align: middle;
			margin-right: .5em;
			opacity: 0;
			border-radius: 50%;
		}
	}
	&:checked
	{
		& + label
		{
			&:after
			{
				opacity: 1;
			}
		}
	}
}


//
// Base styles
//

.btn {
  display: inline-block;
  font-weight:  normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid $gray;
  position: relative;
  background: transparent;
  padding: $button-padding;
  border-radius: 4px;
  text-decoration: none;
  line-height: 1.5;
  color: $gray-dark;
  &:hover
  {
  	background: $gray;
  }
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
    }
  }

  &.focus {
  }

  &:active,
  &.active {
    
  }

  &.disabled,
  &:disabled {
    opacity: .65;
  }
}

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}


//
// Alternate buttons
//

.btn-primary {
	z-index: 1;
	background-color: transparent;
	border-color: $brand-primary;
	color: $brand-primary-text;
	font-size: 22px;
	padding: 7px 35px;
	font-weight: 100;
	&:before,
	&:after
	{
		content: ' ';
		display: block;
		position: absolute;
		border-radius: inherit;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
  	&:before
  	{
		background: $brand-primary;
		z-index: -1;
  	}
  	&:after
  	{
  		background: darken($brand-primary, 5%);
  		z-index: -2;
	}
	&:hover
	{
		&:before
		{
			opacity: 0;
		}
	}
	&.btn-gradient
	{
		&:before
		{
			//@include gradient-h(lighten($brand-primary, 10%), darken($brand-primary, 10%));
			z-index: -1;
		}
	}
}

.btn-link {
  font-weight: normal;
  color: $link-color;
  border-radius: 0;

  &,
  &:active,
  &.active,
  &:disabled {
    background-color: transparent;
    box-shadow: none;
  }
  &,
  &:focus,
  &:active {
    border-color: transparent;
  }
  &:hover {
    border-color: transparent;
  }
  &:hover {
    color: $link-hover-color;
    text-decoration: underline;
    background-color: transparent;
  }
  &:disabled {
    text-decoration: none;
  }
}


//
// Button Sizes
//

.btn-lg {
  // line-height: ensure even-numbered height of button next to large input
}
.btn-sm {
  // line-height: ensure proper height of button next to small input
}


//
// Block button
//

.btn-block {
  display: block;
  width: 100%;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
  margin-top: 5px;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}
