body
{
	
	background-size: auto 110px;
	@include bp('md'){
		background: #fff;
	}
}

.product-nav
{
	position: absolute;	
	top: 0;
	left: 0;
	z-index: 9999;
	background: #fff;
	width: 750px;
	display: flex;
	transform: translateX(-20%);
	header &
	{
		display: none;
	}
	&__section
	{
		padding: 20px;
		width: 50%;
		&:first-child
		{
			border-right: 1px solid transparentize($blue3, .7);
		}
		a
		{
			display: flex;
			align-items: center;
			color: $blue3;
			margin: 15px 0;
			&:hover
			{
				color: $blue1;
			}
			img
			{
				margin-right: 20px;
				width: 60px;
				height: 60px;
				object-fit: contain;
			}		
		}
	}
	&__heading
	{
		display: flex;
		align-items: center;
		h3
		{
			font-size: 18px;
			color: $blue3;
			line-height: 1.3;
			margin-left: 15px;
		}
		img
		{
			width: 80px;
			height: 80px;
			object-fit: contain;
		}
		
	}
}
.geo-section-1,
.geo-section-6
{
	background: url(../../../images/products/geo-1.png) no-repeat center right;
	background-size: auto 100%;
	.layout-container
	{
		height: 100vh;
		max-height: 700px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	h2
	{
		text-align: left;
	}
	p
	{
		font-size: 26px;
		font-weight: bold;
		color: $gray;
		display: block;
	}
}

.pro-section-2
{
	text-align: center;
	line-height: 0;
	.img-wrap
	{
		position: relative;
		&:after
		{
			content: ' ';
			top: 50%;
			left: 50%;
			width: 340px;
			height: 340px;
			z-index: 1;
			background: rgba(0,0,0,0.5);
			border-radius: 50%;
			transform: translateX(-50%) translateY(-50%);
			position: absolute;
		}
		img
		{
			position: relative;
			z-index: 2;
		}
		padding: 60px;
		background: url(../../../images/products/proj-1-bg.png) no-repeat center;
		background-size: cover;
	}
	h2
	{
		text-align: left;
	}
	img
	{
		max-width: 100%;
	}
	&__wrap
	{
		background: #002736;
		
	}
	&__inner
	{
		display: flex;
		padding: 30px 0;
		justify-content: space-between;
		h3
		{
			color: #fff;
			margin: 0;
			margin-bottom: 15px;
		}
	}
}

.pro-section-3
{
	margin: 100px 0;
	&__wrap
	{
		background: url(../../../images/products/proj-2.png) no-repeat center right;
		background-size: cover;
		padding: 30px 0;
	}
	.box
	{
		max-width: 700px;
		background: #fff;
		border-left: 4px solid $blue1;
		display: flex;
		align-items: center;
		& > div
		{
			width: 50%;
			color: $blue3;
			padding: 30px 20px;
		}
		h3
		{
			line-height: 1.3;
			margin: 0;
		}

	}
	.big
	{
		color: $blue3;
		font-size: 25px;
		font-weight: bold;
		max-width: 700px;
		margin-top: 40px;
		line-height: 1.3;
	}
}

.pro-section-4
{
	&__wrapper
	{
		img
		{
			max-width: 100%;
		}
		line-height: 0;
		background: url(../../../images/products/proj-3-1.png) no-repeat center right;
		background-size: auto 100%;
	}
}

.iconed-big
{
	color: $blue3;
	.iconed
	{
		align-items: center;
		margin-bottom: 15px;
		img
		{
			width: 180px;
			margin-right: 20px;
		}
	}
}

.pro-section-5
{
	margin: 100px 0;
	&__wrapper
	{
		background: url(../../../images/products/proj-4-bg.png) no-repeat center;
		background-size: cover;
		img
		{
			max-width: 100%;
		}
		text-align: center;
		line-height: 0;
		//background: url(../../../images/products/proj-3-1.png) no-repeat center right;

	}
}

.pro-section-6
{
	margin-top: 100px;
	&__wrapper
	{
		img
		{
			max-width: 100%;
		}
		background: #e8eae9 url(../../../images/products/proj-5.png) no-repeat center right;
		padding: 80px 0;
		font-size: 22px;

		background-size: auto 100%;
		ul
		{
			list-style-type: square;
			color: $blue3;
			li
			{
				margin: 20px 0;
			}
		}
	}
}

.pro-section-7
{
	margin: 100px 0;
	h2
	{
		margin-bottom: 30px;
	}
	img
	{
		max-width: 100%;
	}
}
.geo-section-6
{
	margin-top: 100px;
}

.geo-section-7
{
	margin-top: 100px;
	h3
	{
		color: $blue3;
		text-align: center;
		margin-bottom: 20px;
	}
	&__wrap
	{
		display: flex;
		line-height: 0;
	}
	.left
	{
		width: 55%;
		img
		{
			max-width: 100%;
		}
		.span
		{
			margin-top: 0;
			background: #c6efff;
			color: #004e6c;
			font-size: 17px;
			font-weight: normal;
			text-align: center;
			line-height: 1.5;
		}
		&__list
		{
			background: #e1e1e1;
			margin-top: 40px;
			padding: 25px 40px;
			color: $blue3;
			font-size: 16px;
			line-height: 1.5;
			h3
			{
				text-align: left;
			}
			ul
			{
				list-style-type: square;
				li
				{
					margin: 10px 0;
					span
					{
						font-size: 13px;
					}
				}
			}
		}
	}
	.right
	{
		width: calc(45% - 30px);
		padding-left: 30px;
		margin-left: 30px;
		border-left: 1px solid $blue1;
		img
		{
			max-width: 100%;
		}
	}
}

.geo-section-2
{
	font-size: 16px;
	&__text
	{
		h3
		{
			margin-top: 50px;
			margin-bottom: 30px;
		}
		p
		{
			font-size: 16px;
		}
	}
	&__chart
	{
		text-align: center;
		text-align: left;
		img
		{
			max-width: 100%;
		}
		background: #d9e0e3;
	}
}

.geo-section-3
{
	margin-top: 100px;
	
	//
	.bg
	{
		background: #d9e0e3;
		padding: 40px 0;
	}
	&__wrap
	{
		display: flex;
		margin-top: 40px;
	}
	.span
	{
		font-size: 18px;
	}
	.left
	{
		display: flex;
		width: 70%;
		justify-content: center;
		flex-wrap: wrap;
		position: relative;
		.item
		{
			width: 50%;
			h3
			{
				border: 2px solid $blue1;
				border-radius: 10px;
				padding: 2px 20px;
				font-size: 16px;
				color: $blue3;
				margin-bottom: 30px;
			}
			ul
			{
				font-size: 16px;
				list-style-type: square;
			}
			& > ul
			{
				& > li
				{
					margin-bottom: 10px;
				}
			}
			&:nth-child(2)
			{
				//padding-right: 200px;
			}
			&:nth-child(3)
			{
				padding-left: 160px;
			}
			&:nth-child(4)
			{
				padding-top: 80px;
			}
		}
		img
		{
			position: absolute;
			top: 100px;
			left: 50%;
			transform: translateX(-50%);
			
		}
	}
	.right
	{
		padding-left: 40px;
		margin-left: 40px;
		border-left: 1px solid $blue1;
		width: calc(30% - 40px);
		.item
		{
			display: flex;
			align-items: center;
			margin-bottom: 40px;
			img
			{
				width: 70px;
				height: 70px;
				margin-right: 30px;
				object-fit: contain;
			}
			span
			{
				font-size: 16px;
				color: $blue3;
			}
		}
	}
}


.geo-section-4
{
	margin-top: 100px;
	img
	{
		max-width: 100%;
	}
}

.geo-section-5
{
	margin-top: 100px;
	.bg
	{
		background: #f2f2f2;
		padding: 40px 0;
	}
	h2
	{
		span
		{
			font-size: 26px;
		}
	}
	.span
	{
		font-size: 22px;
	}
	img
	{
		max-width: 100%;
	}
	&__wrap
	{
		margin-top: 30px;
		display: flex;
		align-items: center;
		ul
		{
			list-style-type: square;
			color: $blue3;
			font-size: 16px;
			li
			{
				margin: 10px 0;
			}
		}
	}
}

.geo-section-8
{
	margin: 100px 0;
	img
	{
		max-width: 100%;
	}
}
.products
{
	display: flex;
	flex-wrap: wrap;
	& > a
	{
		width: 100%;
	    background: #002060;
	    color: #fff;
	    text-decoration: none;
	    padding: 5px 20px;
	    -webkit-transition: all 0.15s ease;
	    -moz-transition: all 0.15s ease;
	    -ms-transition: all 0.15s ease;
	    -o-transition: all 0.15s ease;
	    transition: all 0.15s ease;
	    text-align: center;
	    padding: 15px;
	    font-size: 16px;
	    font-weight: bold;
	    border: 5px solid #80d2f1;
	    &:hover
	    {
	    	background: darken(#002060, 5%);
	    }
	}
	&__wrap
	{
		display: flex;
		align-items: center;

		height: calc(100vh - 110px);
		& > div
		{
			width: 100%;
		}
	}
	&__item
	{
		width: 50%;
		display: flex;
		justify-content: center;
		padding: 50px 25px;
		align-items: center;
		&:last-child
		{
			border-left: 1px dashed $gray;
			img
			{
				width: 160px;
				height: 160px;

			}
		}
		.simple
		{
			font-size: 22px;
		}
		h3
		{
			color: $blue3;
			line-height: 1.5;
			margin-bottom: 20px;
		}
		img
		{
			width: 200px;
			margin-right: 30px;
			height: 200px;
			object-fit: contain;
		}
	}
}

.iconed
{
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	&.iconed-small
	{
		align-items: center;
		margin: 20px 0;
		img
		{
			width: 60px;
			margin-right: 15px;
		}
	}
	h4
	{}
	img
	{
		width: 100px;
		height: auto;
		margin-right: 10px;
	}
}

.vertical-fill
{
	flex-direction: column;
	display: flex;
	padding: 40px 0;
	justify-content: space-between;
}
.cs-full
{
	margin: 90px 0;
	font-size: 18px;
	min-height: calc(100vh - 110px);
	.mobile & {
		min-height: 0;
	}
	h3
	{
		border-bottom: 1px solid $blue3;
		padding-bottom: 7px;
		color: $blue3;
		img
		{
			height: 60px;
			vertical-align: middle;
			margin-right: 10px;
		}
	}
	ul
	{
		margin: 0;
		padding: 10px 0 0 20px;
		list-style-type: square;
		li
		{
			margin-bottom: 20px;
		}
	}
	h4
	{
		
	}
	
}

.green
{
	color: $green1;
}

.green2
{
	color: #51a8a5;
}
.blue
{
	color: $blue1;
}
.blue3
{
	color: $blue3;
}

.box
{
	display: flex;
	justify-content: flex-start;
	margin-top: 20px;
	& > div
	{
		margin-left: 50px;
	}
	& > img
	{
		width: 350px;
		object-fit: contain;
	}
}

.case-btn
{
	background: $blue1;
	color: #fff;
	text-decoration: none;
	padding: 5px 20px;
	@include transition;
	border: 5px solid $blue2;
	&:hover
	{
		background: $blue2;
	}
}

.cases
{
	min-height: calc(100vh - 210px);
	display: flex;
	width: 100%;
	align-items: center;
	.mobile & {
		min-height: 0;
	}
	& > div
	{
		width: 100%;
	}
	h2
	{
		margin-top: 40px;
		text-align: center;
		color: $blue1;
		margin-bottom: 35px;
	}
	&__links
	{	
		display: flex;
		justify-content: flex-start;
		
		flex-wrap: wrap;
		h2
		{
			text-align: left;
		}
	}	
	&__item
	{
		width: 33.3%;
		padding: 40px 20px;
		display: flex;
		align-items: center; 
		border-right: 1px dashed $gray;
		border-bottom: 1px dashed $gray;
		&:nth-child(3n+3)
		{
			border-right: 0;
		}
		&:nth-child(4),
		&:nth-child(5),
		&:nth-child(6)
		{
			border-bottom: 0;
		}
		div
		{
				
		}
		
		h2
		{
			font-size: 28px;
			margin-top: 0;
			color: $blue3;
			margin-bottom: 25px;
		}
		img
		{
			width: 80px;
			margin-right: 30px;
			height: 80px;
			object-fit: contain;
		}
	}
}
.slider
{
	height: 550px;
	position: relative;
	.slick-arrow
	{
		font-size: 60px;
		width: 50px;
		height: 61px;
		background: none;
		border: 0;
		padding: 0;
		color: #fff;
		top: 50%;
		margin-top: -30px;
		position: absolute;
		text-shadow: 0 0 10px rgba(0,0,0,0.5);
		z-index: 100;
		&:focus,
		&:active
		{
			outline: 0;
		}
		&:hover
		{
			text-shadow: 0 0 10px rgba(0,0,0,1);
		}
		&.slick-next
		{
			right: 0;
		}
		&.slick-prev
		{
			left: 0;
		}
	}
	.slide
	{
		height: 550px;
		position: relative;
		
		.btn
		{
			margin-right: 15px;
		}
		img
		{
			z-index: 1;
		}
		.layout-container
		{
			z-index: 2;
			position: relative;
			height: 550px;
			padding-top: 50px;
		}
	}
	.slide1
	{
		display: none;
		
		background: url(../img/slide1-bg.png) repeat-x center;
		background-size: auto 100%;
		color: #004e6c;
		h2
		{
			margin: 0;
			& + p
			{
				margin-top: 10px;
				font-weight: bold;
				font-size: 16px;
			}
		}
		ul
		{
			margin: 0;
			padding: 0;
			list-style: none;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			width: 700px;
			margin: 40px 0;
			li
			{
				width: 50%;
				position: relative;
				font-size: 22px;
				margin: 35px 0;
				&:before
				{
					content: ' ';
					display: block;
					width: 70px;
					height: 70px;
					position: absolute;
					top: 50%;
					left: 0;
					margin-top: -35px;
					background: url(../img/check.png) no-repeat center;
				}
				padding-left: 80px;
			}
		}
		img
		{
			max-width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;
		}
	}
	.slide2
	{
		background: url(../img/slide2-bg.png) repeat-x center;
		background-size: auto 100%;
		.layout-container
		{
			padding-top: 0;
		}
		color: #004e6c;
		.btn
		{
			position: absolute;
			bottom: 65px;
			left: 50%;
			transform: translateX(-50%);
			margin: 0;
			z-index: 100;
		}
		img
		{
			margin-top: 60px;
			max-height: 550px;
			max-width: 100%;
		}
	}
	.slide3
	{
		background: url(../img/slide3-bg.png) repeat-x center;
		background-size: cover;
		padding-top: 0;
		color: #004e6c;
		img
		{
			max-width: 1100px;
			margin-top: 90px;
		}
		.btn
		{
			position: absolute;
			bottom: 40px;
			right: 0;
			//transform: translateX(-440px);
		}
		
	}
	.slide4
	{
		background: url(../img/slide4-bg.png) repeat-x center;
		background-size: auto 100%;
		color: #004e6c;
		position: relative;
		.btn
		{
			position: absolute;
			bottom: 40px;
			left: 50%;
			transform: translateX(-440px);
		}
		&__item
		{
			margin-top: 10px;
			font-weight: bold;
			font-size: 16px;
			position: absolute;
			top: 0;
			margin-top: 70px;
			margin-left: 20px;
			left: 50%;
			transform: translateX(-50%);
			&:nth-child(1)
			{
				top: 230px;
				text-align: right;
				margin-left: -350px;
			}
			&:nth-child(2)
			{
				top: 110px;
				text-align: right;
				margin-left: -280px;
			}
			&:nth-child(3)
			{
				
			}
			&:nth-child(4)
			{
				top: 110px;
				text-align: left;
				margin-left: 330px;			
				
			}
			&:nth-child(5)
			{
				
				top: 230px;
				text-align: left;
				margin-left: 400px;
				
			}

		}
	}
	
}

main
{
	max-width: 800px;
	padding-bottom: 100px;
}

.span
{
	padding: 30px 20px;
	background: $blue1;
	color: #fff;
	font-size: 15px;
	margin-top: 50px;
	font-weight: bold;
}

.benefits
{
	background: $blue3;
	padding: 60px 0;
	color: #fff;
	margin-bottom: 60px;
	&__wrap
	{
		display: flex;

		justify-content: space-between;
	}
	&__item
	{
		width: calc(33% - 20px);
		margin-right: 30px;
		white-space: nowrap;
		text-align: center;
		img
		{
			height: 100px;
			vertical-align: middle;
			margin-right: 20px; 
		}
		span
		{
			display: inline-block;
			vertical-align: middle;
			text-align: left;
			white-space: normal;
		}
		h3
		{
			margin: 0;
			text-align: left;
			font-size: 24px;
			vertical-align: middle;
			line-height: 1.3;
			font-weight: normal;
		}
		p
		{
			font-weight: 100;
		}
		a
		{
			color: lighten($blue1, 25%);
			@include transition;
			&:hover
			{
				color: lighten($blue1, 35%);
			}
		}
		&:last-child
		{
			margin-right: 0;
		}
	}
}

$itemH: 200px;
.goToTop
{
	position: fixed;
	background: $brand-primary;
	bottom: 30px;
	right: 30px;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	border: 0;
	display: none;
	color: #fff;
	font-size: 25px;
	&:focus,
	&:active
	{
		outline: 0;
	}
	&:hover
	{
		background: darken($brand-primary, 10%); 
	}
}
.customers
{
	background: url(../img/customers-bg.png) no-repeat center;
	background-size: cover;
	position: relative;	
	/*&:after
	{
		content: ' ';
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 100;
		width: 150px;
		height: 150px;
		background: url(../img/logo2.png) no-repeat center;
		background-size: 100% auto;
		margin-left: -75px;
		margin-top: -95px;
	}*/
	&__wrap
	{
		height: 80vh;	
		min-height: 800px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-content: center;
		img
		{
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
	&__item
	{
		width: calc(50% - 25px);
		margin-bottom: 50px;
		padding: 30px 0;
		color: $blue3;
		position: relative;
		&:before
		{
			content: ' ';
			display: block;
			
			position: absolute;
			width: $itemH + 60px;
		}
		&.custom1
		{
			padding-right: 30px;
			&:before
			{
				top: 0;
				background: #00a1e0;
				right: 0;
				bottom: 0;
				border-radius: 50% 50% 0 50%;
			}
		}
		&.custom2
		{
			padding-left: 30px;
			&:before
			{
				top: 0;
				background: #bbe499;
				left: 0;
				bottom: 0;
				border-radius: 50% 50% 50% 0;
			}
		}
		&.custom3
		{
			padding-right: 30px;
			&:before
			{
				top: 0;
				background: #80d2f1;
				right: 0;
				bottom: 0;
				border-radius: 50% 0 50% 50%;
			}
		}
		&.custom4
		{
			padding-left: 30px;
			&:before
			{
				top: 0;
				background: #6fbe44;
				left: 0;
				bottom: 0;
				border-radius: 0 50% 50% 50% ;
			}
		}
		
		&__title
		{
			background: #f1f1f1;
			height: $itemH;
			width: $itemH;
			border-radius: 50%;
			text-align: center;
			align-items: center;
			display: flex;
			
			font-weight: 600;
			font-size: 18px;
			b
			{
				font-weight: 800;
			}
			div
			{
				width: 100%;
			}
		}
		&__body
		{
			display: flex;
			align-items: center;
			height: $itemH;
			border-radius:  $itemH/2;
			position: relative;
			justify-content: space-between;
			background: #fff;
			ul
			{
				padding: 0;
				padding-right: 30px;
				padding-left: 80px;
				list-style: none;
				margin: 0;
				width: calc(100% - 200px);
				li
				{
					line-height: 1.2;
					margin: 5px 0;
					padding-left: 10px;
					position: relative;
					&:before
					{
						content: '-';
						position: absolute;
						top: 0;
						left: 0;
					}
				}
			}
			.custom2 & ul,
			.custom4 & ul
			{
				padding-right: 0;
				padding-left: 30px;
			}
		}
	}
}


.customer-logos
{
	margin-top: 60px;
	h2
	{
		color: $blue3;
		border-bottom: 2px solid $blue3;
		font-size: 25px;
		text-transform: uppercase;
		font-weight: 900;
		margin-bottom: 0;
		&.green
		{
			color: $green1;
			border-color: $green1;
		}
		&.blue
		{
			color: $blue1;
			border-color: $blue1;
		}
	}
	&__wrap
	{
		display: flex;
		flex-wrap: wrap;
		align-content: flex-start;
		//border-top: 1px solid #f1f1f1;
		//border-left: 1px solid #f1f1f1;
		span
		{
			width: calc(100%/6);
			height: 200px;
			position: relative;
			text-align: center;
			//border-right: 1px solid #f1f1f1;
			//border-bottom: 1px solid #f1f1f1;
			line-height: 200px;

			&:hover
			{
				img
				{
					//filter: grayscale(0%);
					opacity: 1;
				}
			}
			img
			{
				max-width: 80%;
				vertical-align: middle;
				max-height: 80%;
				align-items: center;
				//filter: grayscale(100%);
				@include transition;
				opacity: .75;
			}
		}
		
	}
}

.section
{
	h2
	{
		color: #004e6c;
		border-bottom: 2px solid #004e6c;
		font-size: 25px;
		text-transform: uppercase;
		font-weight: 900;
		margin-bottom: 0;
	}
}
.layout-container
{
	.partner-span
	{
		display: none;
		ul
		{
			margin: 0;
			padding: 0;
		}
	}
}

.partner-span
{
	padding: 0 15px 15px 15px; 
	ul
	{
		margin: 0;
		padding: 0;
		padding-left: 20px;
	}
}
.hightlights
{
	padding: 80px 0;
	.span
	{
		font-size: 18px;
		line-height: 1.5;
	}
	&__wrap
	{
		display: flex;
		justify-content: space-between;
		margin-top: 60px;
	}

	&__item
	{
		width: 20%;
		padding: 0 20px;
		text-align: center;
		position: relative;
		font-size: 20px;
		color: #002060;
		&:nth-child(1)
		{
			.hightlights__item__icon:before,
			.hightlights__item__icon:after
			{
				border-color: #00a1e0;
			}
		}
		&:nth-child(2)
		{
			.hightlights__item__icon:before,
			.hightlights__item__icon:after
			{
				border-color: #80d2f1;
			}
			.hightlights__item__icon
			{
				padding-bottom: 50px;
			}
		}
		&:nth-child(3)
		{
			.hightlights__item__icon:before,
			.hightlights__item__icon:after
			{
				border-color: #539032;
			}
		}
		&:nth-child(4)
		{
			.hightlights__item__icon:before,
			.hightlights__item__icon:after
			{
				border-color: #6fbe44;
			}
			.hightlights__item__icon
			{
				padding-bottom: 50px;
			}
		}
		&:nth-child(5)
		{
			.hightlights__item__icon:before,
			.hightlights__item__icon:after
			{
				border-color: #546974;
			}
		}
		&__icon
		{
			padding-bottom: 130px;
			position: relative;
			border-color: red;
			margin-bottom: 15px;
			&:before
			{
				content: '';
				position: absolute;
				top: 50px;
				left: 50%;
				border-left-width: 1px;
				border-left-style: solid;
				border-left-color: red;
				bottom: 0;
				z-index: 1;
			}
			&:after
			{
				content: '';
				position: absolute;
				bottom: 0;
				left: 60px;
				right: 60px;
				border-top-width: 1px;
				border-top-style: solid;
				border-top-color: red;
				bottom: 0;
				z-index: 1;
			}
			img
			{
				max-width: 100%;
				position: relative;
				z-index: 2;
			}
		}
	}
}

.team {
	padding: 20px 0 80px;
	
	&__wrap
	{
		display: flex;
		justify-content: space-between;
		margin-top: 60px;
	}
	&__title
	{
		height: 70px;
	}
	&__item
	{
		width: 25%;
		padding: 20px;
		text-align: left;
		position: relative;
		&:last-child
		{
			&:after
			{
				opacity: .75;
			}
			p
			{
				color: $blue3;
				margin-bottom: 10px;
				font-size: 16px;
			}
		}
		&.green
		{
			h3
			{
				color: $green1;
			}

		}
		
		&:after
		{
			content: ' ';
			position: absolute;
			@include transition;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: #fff;
			z-index: -1;
		}
		&:before
		{
			content: ' ';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: url(../img/team-bg.png) no-repeat center;
			background-size: cover;
			z-index: -2;
		}

		h3
		{
			color: $blue1;
			line-height: 1.3;
			& + p {
				margin-top: 5px;
				color: $blue1;
				line-height: 1;
			}
		}
		&__body
		{
			border-top: 1px solid $blue1;
			padding-top: 20px;
			.green &
			{
				border-color: $green1;
			}
		}
	}
}

.btn-wrap
{
	display: flex;
	& > a
	{
		background: #00a1e0;
		color: #fff;
		text-decoration: none;
		padding: 10px 20px;
		-webkit-transition: all 0.15s ease;
		-moz-transition: all 0.15s ease;
		-ms-transition: all 0.15s ease;
		-o-transition: all 0.15s ease;
		transition: all 0.15s ease;
		border: 5px solid #80d2f1;
		font-size: 16px;
		margin-right: 25px;
		&:hover
		{
			border: 5px solid #00a1e0;
		}
	}
}

.network
{
	font-size: 16px;
	color: $blue3;
	margin: 80px 0;
	h2
	{
		 color: #00a1e0;
	}
	.bg
	{
		background: #d9e0e3;
		padding: 30px 0;
	}
	h3
	{
		font-weight: bold;
	}
	&-1
	{
		
		&__section
		{
			display: flex;
			align-items: center;
			&.right
			{
				justify-content: flex-end;
				text-align: right;
				img
				{
					margin-right: 0;
					margin-left: 40px;
				}
			}
			img
			{
				margin-right: 40px;
			}
		}
	}
	&-2
	{
		&__inner
		{
			display: flex;
			margin-bottom: 30px;
		}
		ul
		{
			list-style-type: square;
			margin-left: 30px;
			li
			{
				margin: 15px 0;
				font-size: 18px;
			}
		}
	}
	&-3
	{
		.bg
		{
			background-image: url(../../../images/network/3-1.png);
			background-color: #eaedef;
			background-repeat: no-repeat;
			background-position: right top;
			background-size: auto 100%;
			padding: 70px 0;
		}
		ul
		{
			list-style-type: square;
			margin-left: 30px;
			li
			{
				margin: 25px 0;
				font-size: 22px;
			}
		}
	}
	&-4
	{
		.bg
		{
			background-image: url(../../../images/network/4-1.png);
			background-color: #e7e7e7;
			background-repeat: no-repeat;
			background-position: right top;
			background-size: auto 100%;
			padding: 70px 0;
		}
		.iconed
		{
			align-items: center;
			font-size: 22px;
			margin: 15px 0;
		}
	}
	&-5
	{
		img
		{
			max-width: 100%;
		}
	}
	&-6
	{
		.big
		{
			font-size: 18px;
			font-weight: bold;
		}
		p
		{
			font-weight: bold;
		}
		h4
		{
			color: $blue1;
			text-decoration: underline;
			margin-bottom: 15px;
		}
		h5
		{
			color: $blue1;
			font-size: 16px;
			font-weight: bold;
		}
		ul
		{
			list-style-type: square;
			li
			{
				margin: 7px 0;
			}
		}
		.bg
		{
			background: url(../../../images/network/6-1.png) center right;
			background-size: cover;
		}
		img
		{
			max-width: 100%;
		}
	}
	&-7
	{
		.bg
		{
			background: url(../../../images/network/7-1.png) center right;
			background-size: cover;
			padding: 80px 0;
		}
		ul
		{
			list-style-type: square;
			max-width: 800px;
			li
			{
				margin: 40px 0;
				font-size: 18px;
			}
		}
	}
	&-8
	{
		.btn
		{
			margin-right: 20px;
		}
	}
	&-10
	{
		.bg
		{
			background: url(../../../images/network/10-1.png) center right;
			background-size: cover;	
		}
		ul
		{
			list-style-type: square;
			padding-left: 20px;
		}
		
	}
	&-11
	{
		&__inner
		{
			display: flex;
			align-items: center;
			img
			{
				margin-right: 30px;
				width: 700px;
				object-fit: contain;
			}
		}
		.iconed
		{
			align-items: center;
			font-size: 22px;
			font-weight: bold;
			img
			{
				width: 65px;
			}
		}
	}
	&-15
	{
		.bg
		{
			background: #e5e3e4 url(../../../images/network/15-1.png) no-repeat center right;
			
		}
		.layout-container
		{
			padding-right: 450px;
		}
		p
		{
			font-size: 18px;
		}
		ul
		{
			list-style-type: square;

			padding-left: 20px;
			li
			{
				margin: 20px 0;
			}
		}
	}
	&-16
	{
		img
		{
			max-width: 100%;
		}
	}

	&-17
	{
		img
		{
			max-width: 100%;
		}
	}
}