body {
  font-family: $font-heading;
  font-weight: 400;
  font-style: normal;
  font-size: $font-size-default;
  line-height: $font-margin;
}


h1,
h2,
h3,
h4,
h5
{
	font-family: $font-heading;
	font-style: inherit;
	margin-bottom: 0px;
	font-weight: 600;
}

h1 {
	
  font-size: (($font-size-default * $font-scale) * $font-scale) * $font-scale;
  line-height: $font-margin * 3;
  margin-top: $font-margin;
}
h2 {
  font-size: ($font-size-default * $font-scale) * $font-scale;
  line-height: $font-margin * 2;
  margin-top: $font-margin;
  margin-bottom: $font-margin;
}
h3 {
  font-size: $font-size-default * $font-scale;
  line-height: $font-margin * 2;
  margin-top: $font-margin;
}
h4 {
  font-size: $font-size-default * $font-scale;
  line-height: $font-margin;
  margin-top: $font-margin;
  margin-bottom: 7px;
}

h5 {
  font-size: (($font-size-default * $font-scale) * $font-scale) * $font-scale;
  font-weight: 400;
  line-height: $font-margin * 3;
  margin-top: $font-margin;
}

p, ul, ol, pre, table, blockquote, figure {
  margin-top: 0px;
  margin-bottom: $font-margin;
}
ul ul, ol ol, ul ol, ol ul {
  margin-top: 0;
  margin-bottom: 0;
}
ul, ol {
  padding-left: span(1);
}
hr {
  border: 1px solid;
  margin: -1px 0;
}
b, strong, em, small, code {
  line-height: 1;
}
sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}

.text-left {text-align: left;}
.text-center {text-align: center;}
.text-right {text-align: right;}
.text-justify {text-align: justify;}
.text-nowrap {text-transform: nowrap;}
.text-lowercase {text-transform: lowercase;}
.text-uppercase {text-transform: uppercase;}
.text-capitalize {text-transform: capitalize;}

blockquote
{
	margin-left: 0;
	margin-right: 0;
  padding: $space-y * 2 span(1);
  cite
  {
    display: block;
    text-align: right;
    font-style: italic;
  }
  & > *:last-child
  {
    margin-bottom: 0;
  }
}


.text-right
{
  text-align: right;
}

.text-left
{
  text-align: left;
}

.text-center
{
  text-align: center;
}

.pull-quote
{
	background: $gray-light;
  position: relative;
  &:before,
  &:after
  {
    height: 0.5em;
    line-height: 1;
    opacity: 0.5;
    position: absolute;
    font-size: 4em;
    color: $gray-dark;
  }
  &:before
  {
    content: '“';
    top: $space-y;
    left: $space-y*1.5;
  }
  &:after
  {
    content: '”';
    bottom: $space-y;
    right: $space-y*1.5;
  }
}

figure
{
  padding: $space-y;
  padding-bottom: $space-y * 2;
  background: $gray-light;
  figcaption
  {
    color: $gray-dark;
    margin-top: 0.5em;
  }
  img
  {
    max-width: 100%;
  }
}

.help-block
{
    display: block;
    margin-top: 0.3em;
    margin-bottom: 0.6em;
    color: $gray-dark;
}

main ul:not([class])
{
    list-style: none;
    li
    {
        //margin-left: -1.4em;
        &:before
        {
          content: '\2014\a0';
          color: $brand-primary;
          font: 1em Arial;
          margin-left: -1.6em;
          position: absolute;
          line-height: 1.6;
        }
    }
}


