/*
Icon classes can be used entirely standalone. They are named after their original file names.

```html
<!-- `display: block` sprite -->
<div class="icon-home"></div>

<!-- `display: inline-block` sprite -->
<img class="icon-home" />
```
*/
.icon-1hour {
  background-image: url(../img/sprite.png);
  background-position: -55px -55px;
  width: 49px;
  height: 55px;
}
.icon-big-feedback {
  background-image: url(../img/sprite.png);
  background-position: 0px 0px;
  width: 55px;
  height: 55px;
}
.icon-big-gift {
  background-image: url(../img/sprite.png);
  background-position: -55px 0px;
  width: 55px;
  height: 55px;
}
.icon-clock {
  background-image: url(../img/sprite.png);
  background-position: -110px -49px;
  width: 20px;
  height: 20px;
}
.icon-cn {
  background-image: url(../img/sprite.png);
  background-position: -110px -69px;
  width: 23px;
  height: 17px;
}
.icon-en {
  background-image: url(../img/sprite.png);
  background-position: -110px -86px;
  width: 23px;
  height: 17px;
}
.icon-hamburger {
  background-image: url(../img/sprite.png);
  background-position: -110px -32px;
  width: 24px;
  height: 17px;
}
.icon-interchange {
  background-image: url(../img/sprite.png);
  background-position: -110px 0px;
  width: 32px;
  height: 32px;
}
.icon-ru {
  background-image: url(../img/sprite.png);
  background-position: 0px -110px;
  width: 23px;
  height: 17px;
}
.icon-savemoney {
  background-image: url(../img/sprite.png);
  background-position: 0px -55px;
  width: 55px;
  height: 55px;
}
