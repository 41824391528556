.map
{
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 10;
	&__wrap
	{
		position: relative;
		padding: 70px;
		min-height: 80vh;
	}
	&__info
	{
		width: 330px;
		h3
		{
			margin-top: 0;
			margin-bottom: 20px;
		}
		.address
		{
			font-size: 18px;
		}
		z-index: 50;
		position: relative;
		color: #fff;
		background: transparentize(#002060,.1);
		padding: 40px;
		&__contacts
		{
			margin-top: 120px;
			color: lighten($blue1, 25%);
			line-height: 1.5;
			font-size: 18px;
		}
	}
}